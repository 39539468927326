import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { BillboardDetails, BuilderCampaignType, BusinessInfo, CampaignCreateStep, CreativeBuilderStep, CreativeBuilderType, CreativeCreateStep, CreativeType, DisplayDetails, NativeDetails, TargetStep, VideoDetails } from 'workflows/CampaignCreateWorkflow'

import { AcImage, TvCommercial } from 'api/interfaces'
import { CampaignDelivery } from 'api/interfaces/CampaignDelivery'
import { AudienceTargeting, LocationTargeting, LocationTargetingType, Retargeting, SmartTargeterAreaType, SmartTargeting, TargetingType } from 'api/interfaces/CampaignTargeting'
import { RootState } from 'app/rootReducer'
import { setAudienceTargeting, setBillboardDetails, setCampaignCreateStep, setCampaignDelivery, setCampaignInfo, setCampaignType, setCreativeBuilderStep, setCreativeBuilderType, setCreativeCreateStep, setCreativeId, setCreativeInfo, setDisplayDetails, setLocationTargeting, setNativeDetails, setSmartTargeting, setTargetingType, setTargetStep, setVideoDetails } from 'features/builders/CampaignCreateSlice'

import { getAudienceTargetingFromTemplate, getAudienceTemplateByCampaignType, getCampaignDeliveryFromTemplate, getCampaignDeliveryTemplateByCampaignType, getLocationTargetingFromTemplate, getLocationTemplateByCampaignType, getSegmentsFromTemplate } from '../../../utils/TransformTargeting'
import { DefaultTargetingState } from '../DefaultTargetingSlice'

export const useCampaignBuilderActions = () => {
  const campaignCreateWorkflow = useSelector((state: RootState) => state.campaignCreateWorkflow)
  const dispatch = useDispatch()
  const [builderComplete, setBuilderComplete] = useState<builderComplete>({ isComplete: false })

  const { campaignType } = campaignCreateWorkflow

  // Begin Campaign
  const beginTvCampaign = () => {
    dispatch(setCampaignType(BuilderCampaignType.TV))
    dispatch(
      setCampaignInfo({
        name: '',
        budget: 200,
        contactName: '',
        phone: '',
        email: '',
        isTotalBudget: false
      })
    )
    navigateCreate()
  }

  const beginInternetCampaign = () => {
    dispatch(setCampaignType(BuilderCampaignType.Display))
    dispatch(
      setCampaignInfo({
        name: '',
        budget: 70,
        contactName: '',
        phone: '',
        email: '',
        isTotalBudget: false
      })
    )
    navigateCreate()
  }

  const beginBillboardCampaign = () => {
    dispatch(setCampaignType(BuilderCampaignType.Billboard))
    dispatch(
      setCampaignInfo({
        name: '',
        budget: 150,
        contactName: '',
        phone: '',
        email: '',
        isTotalBudget: false
      })
    )
    navigateCreate()
  }

  const navigateCreate = () => {
    dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilderModeSelect))
    dispatch(setCampaignCreateStep(CampaignCreateStep.CreativeCreate))
  }

  // Ad Builder Select

  const builderNativeSuggestedSelected = () => {
    dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilding))
    dispatch(setCreativeBuilderType(CreativeBuilderType.PreMade))
    dispatch(setCampaignType(BuilderCampaignType.Native))
    dispatch(setCreativeBuilderStep(CreativeBuilderStep.BusinessInfo))
    const creativeId = uuidv4()
    dispatch(setCreativeId(creativeId))
    dispatch(
      setCreativeInfo({
        creativeType: CreativeType.Native,
        creativeId: creativeId
      })
    )
  }

  const builderNativeUploadSelected = () => {
    dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilding))
    dispatch(setCreativeBuilderType(CreativeBuilderType.AdBuilder))
    dispatch(setCreativeBuilderStep(CreativeBuilderStep.AdBuilder))
    dispatch(setCampaignType(BuilderCampaignType.Native))
    const creativeId = uuidv4()
    dispatch(setCreativeId(creativeId))
    dispatch(
      setCreativeInfo({
        creativeType: CreativeType.Native,
        creativeId: creativeId
      })
    )
  }

  const builderDisplayUploadSelected = () => {
    dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilding))
    dispatch(setCreativeBuilderType(CreativeBuilderType.Upload))
    dispatch(setCreativeBuilderStep(CreativeBuilderStep.Upload))
    dispatch(setCampaignType(BuilderCampaignType.Display))
    const creativeId = uuidv4()
    dispatch(setCreativeId(creativeId))
    dispatch(
      setCreativeInfo({
        creativeType: CreativeType.Display,
        creativeId: creativeId
      })
    )
  }

  const builderDisplayCustomSelected = () => {
    dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilding))
    dispatch(setCreativeBuilderType(CreativeBuilderType.Custom))
    dispatch(setCreativeBuilderStep(CreativeBuilderStep.Custom))
    dispatch(setCampaignType(BuilderCampaignType.Display))
    const creativeId = uuidv4()
    dispatch(setCreativeId(creativeId))
    dispatch(
      setCreativeInfo({
        creativeType: CreativeType.Custom,
        creativeId: creativeId
      })
    )
  }

  const builderTvSuggestedSelected = () => {
    dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilding))
    dispatch(setCreativeBuilderType(CreativeBuilderType.PreMade))
    dispatch(setCreativeBuilderStep(CreativeBuilderStep.BusinessInfo))
    dispatch(setCampaignType(BuilderCampaignType.TV))
    const creativeId = uuidv4()
    dispatch(setCreativeId(creativeId))

    dispatch(
      setCreativeInfo({
        creativeType: CreativeType.Video,
        creativeId: creativeId
      })
    )
  }

  const builderTvUploadSelected = () => {
    dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilding))
    dispatch(setCreativeBuilderType(CreativeBuilderType.Upload))
    dispatch(setCreativeBuilderStep(CreativeBuilderStep.Upload))
    dispatch(setCampaignType(BuilderCampaignType.TV))
    const creativeId = uuidv4()
    dispatch(setCreativeId(creativeId))
    dispatch(
      setCreativeInfo({
        creativeType: CreativeType.Video,
        creativeId: creativeId
      })
    )
  }

  const builderTvCustomSelected = () => {
    dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilding))
    dispatch(setCreativeBuilderType(CreativeBuilderType.Custom))
    dispatch(setCreativeBuilderStep(CreativeBuilderStep.Custom))
    dispatch(setCampaignType(BuilderCampaignType.TV))
    const creativeId = uuidv4()
    dispatch(setCreativeId(creativeId))
    dispatch(
      setCreativeInfo({
        creativeType: CreativeType.Custom,
        creativeId: creativeId
      })
    )
  }

  const builderBillboardUploadSelected = () => {
    dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilding))
    dispatch(setCreativeBuilderType(CreativeBuilderType.Upload))
    dispatch(setCreativeBuilderStep(CreativeBuilderStep.Upload))
    dispatch(setCampaignType(BuilderCampaignType.Billboard))
    const creativeId = uuidv4()
    dispatch(setCreativeId(creativeId))
    dispatch(
      setCreativeInfo({
        creativeType: CreativeType.Billboard,
        creativeId: creativeId
      })
    )
  }

  const builderBillboardCustomSelected = () => {
    dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilding))
    dispatch(setCreativeBuilderType(CreativeBuilderType.Custom))
    dispatch(setCreativeBuilderStep(CreativeBuilderStep.Custom))
    dispatch(setCampaignType(BuilderCampaignType.Billboard))
    const creativeId = uuidv4()
    dispatch(setCreativeId(creativeId))
    dispatch(
      setCreativeInfo({
        creativeType: CreativeType.Custom,
        creativeId: creativeId
      })
    )
  }

  // Select Ad

  const customAdContinue = () => {
    const creativeId = uuidv4()
    dispatch(setCreativeId(creativeId))
    dispatch(
      setCreativeInfo({
        creativeId: creativeId,
        creativeType: CreativeType.Custom,
        isCustom: true
      })
    )
    dispatch(setCampaignCreateStep(CampaignCreateStep.Target))
    if (campaignType === BuilderCampaignType.Native || campaignType === BuilderCampaignType.Display) {
      dispatch(setTargetStep(TargetStep.Choose))
    } else {
      dispatch(setCampaignCreateStep(CampaignCreateStep.Target))
      dispatch(setTargetStep(TargetStep.Define))
      dispatch(setTargetingType(TargetingType.Location))
    }
  }

  const suggestedNativeSelected = (businessInfo: BusinessInfo, image: AcImage, headline: string, body: string) => {
    const creativeId = uuidv4()
    dispatch(setCreativeId(creativeId))
    dispatch(
      setNativeDetails([
        {
          creativeId: creativeId,
          headline,
          body,
          companyName: businessInfo.companyName,
          buttonText: 'Learn More',
          clickUrl: businessInfo.websiteUrl,
          acImage: image
        }
      ])
    )
    dispatch(setCreativeBuilderStep(CreativeBuilderStep.AdBuilder))
  }

  const suggestedTvSelected = (tvCommercial: TvCommercial) => {
    const creativeId = uuidv4()
    dispatch(setCreativeId(creativeId))
    dispatch(
      setVideoDetails([
        {
          creativeId: creativeId,
          baseVideoId: tvCommercial.id,
          kind: tvCommercial.kind,
          name: tvCommercial.name,
          previewUrl: tvCommercial.previewUrl,
          quality: tvCommercial.quality,
          script: tvCommercial.script,
          sections: tvCommercial.sections,
          thumbnailUrl: tvCommercial.thumbnailUrl,
          type: tvCommercial.type
        }
      ])
    )
    dispatch(setCreativeBuilderStep(CreativeBuilderStep.AdBuilder))
  }

  // Create Creative Complete
  const createNativeComplete = (adDetails: NativeDetails) => {
    const creativeId = uuidv4()
    dispatch(setNativeDetails([{ ...adDetails, creativeId }]))
    dispatch(setCreativeId(creativeId))
    navigateTargeting()
  }

  const createDisplayComplete = (adDetails: DisplayDetails) => {
    const creativeId = uuidv4()
    dispatch(setDisplayDetails([{ ...adDetails, creativeId }]))
    dispatch(setCreativeId(creativeId))
    navigateTargeting()
  }

  const createBillboardComplete = (adDetails: BillboardDetails) => {
    const creativeId = uuidv4()
    dispatch(setBillboardDetails([{ ...adDetails, creativeId }]))
    dispatch(setCreativeId(creativeId))
    navigateTargeting()
  }

  const createTvComplete = (adDetails: VideoDetails) => {
    const creativeId = uuidv4()
    dispatch(setVideoDetails([{ ...adDetails, creativeId }]))
    dispatch(setCreativeId(creativeId))
    navigateTargeting()
  }

  const navigateTargeting = () => {
    dispatch(setCampaignCreateStep(CampaignCreateStep.Target))
    if (campaignType === BuilderCampaignType.TV) {
      dispatch(setTargetingType(TargetingType.Location))
      dispatch(setTargetStep(TargetStep.Define))
    } else if (campaignType === BuilderCampaignType.Billboard) {
      dispatch(setTargetingType(TargetingType.Location))
      dispatch(setTargetStep(TargetStep.Define))
    } else {
      dispatch(setTargetStep(TargetStep.Choose))
    }
  }

  // Delivery Complete

  const deliveryComplete = (deliveryDetails: CampaignDelivery) => {
    dispatch(setCampaignDelivery(deliveryDetails))
    dispatch(setCampaignCreateStep(CampaignCreateStep.Launch))
  }

  // Targeting Mode Select

  const targetAudienceSelected = () => {
    dispatch(setTargetingType(TargetingType.Audience))
    dispatch(setTargetStep(TargetStep.Define))
  }

  const targetCustomAudienceSelected = () => {
    dispatch(setTargetingType(TargetingType.CustomAudience))
    dispatch(setTargetStep(TargetStep.Define))
  }

  const targetLocationSelected = () => {
    dispatch(setTargetingType(TargetingType.Location))
    dispatch(setTargetStep(TargetStep.Define))
  }

  const targetRetargetingSelected = () => {
    dispatch(setTargetingType(TargetingType.Retarget))
    dispatch(setTargetStep(TargetStep.Define))
  }

  const targetSmartSelected = (targeting: SmartTargeting) => {
    dispatch(setSmartTargeting(targeting))
    dispatch(setTargetingType(TargetingType.Smart))
    dispatch(setTargetStep(TargetStep.Define))
  }

  const targetImportTemplateSelected = (defaultTargetingTemplate: DefaultTargetingState, campaignType: BuilderCampaignType) => {
    const audienceTemplate = getAudienceTemplateByCampaignType(defaultTargetingTemplate, campaignType)
    const locationTemplate = getLocationTemplateByCampaignType(defaultTargetingTemplate, campaignType)
    const campaignDeliveryTemplate = getCampaignDeliveryTemplateByCampaignType(defaultTargetingTemplate, campaignType)
    const segments = getSegmentsFromTemplate(defaultTargetingTemplate, campaignType)

    dispatch(setLocationTargeting(getLocationTargetingFromTemplate(locationTemplate)))
    dispatch(setAudienceTargeting(getAudienceTargetingFromTemplate(audienceTemplate, segments)))
    dispatch(setCampaignDelivery(getCampaignDeliveryFromTemplate(campaignDeliveryTemplate)))
    dispatch(setTargetingType(defaultTargetingTemplate.targetingPriority))
    dispatch(setTargetStep(TargetStep.Import))
  }

  // Targeting Complete

  const targetAudienceComplete = (targetingDetails: LocationTargeting, audienceDetails: AudienceTargeting, deliveryDetails: CampaignDelivery) => {
    dispatch(setLocationTargeting(targetingDetails))
    dispatch(setAudienceTargeting(audienceDetails))
    dispatch(setCampaignDelivery(deliveryDetails))
    navigateDelivery()
  }

  const targetImportTemplateComplete = (targetingDetails: LocationTargeting, audienceDetails: AudienceTargeting, deliveryDetails: CampaignDelivery, targetingPriority: TargetingType) => {
    dispatch(setLocationTargeting(targetingDetails))
    dispatch(setAudienceTargeting(audienceDetails))
    dispatch(setCampaignDelivery(deliveryDetails))
    dispatch(setTargetingType(targetingPriority))
    navigateDelivery()
  }

  const targetLocationComplete = (targetingDetails: LocationTargeting, audienceDetails: AudienceTargeting, deliveryDetails: CampaignDelivery) => {
    dispatch(setLocationTargeting(targetingDetails))
    dispatch(setAudienceTargeting(audienceDetails))
    dispatch(setCampaignDelivery(deliveryDetails))
    navigateDelivery()
  }

  const targetRetargetingComplete = (retargetingInfo: Retargeting) => {
    dispatch(
      setAudienceTargeting({
        demographics: {
          ageRanges: [],
          householdIncomes: [],
          genders: []
        },
        segments: [],
        uploadedAudienceUrl: '',
        retargeting: retargetingInfo
      })
    )
    dispatch(
      setLocationTargeting({
        targetingType: LocationTargetingType.Region,
        regions: [],
        dmas: [],
        cities: [],
        zips: [],
        addresses: []
      })
    )
    navigateDelivery()
  }

  const navigateDelivery = () => {
    if (campaignType === BuilderCampaignType.Billboard) {
      dispatch(setCampaignCreateStep(CampaignCreateStep.Launch))
    } else {
      dispatch(setCampaignCreateStep(CampaignCreateStep.Deliver))
    }
  }

  // Skip

  const skipAdBuilder = () => {
    dispatch(setCampaignCreateStep(CampaignCreateStep.Target))
    if (campaignType === BuilderCampaignType.Native || campaignType === BuilderCampaignType.Display) {
      dispatch(setTargetStep(TargetStep.Choose))
    } else {
      dispatch(setTargetStep(TargetStep.Define))
      dispatch(setTargetingType(TargetingType.Location))
    }
  }

  const skipTargeting = () => {
    if (campaignType === BuilderCampaignType.Billboard) {
      dispatch(setCampaignCreateStep(CampaignCreateStep.Launch))
    } else {
      dispatch(setCampaignCreateStep(CampaignCreateStep.Deliver))
    }
  }

  // Is Ready to Create

  useEffect(() => {
    if (campaignType === BuilderCampaignType.Default) {
      setBuilderComplete({
        isComplete: false,
        isCreateComplete: false,
        isDeliveryComplete: false,
        isTargetComplete: false,
        note: 'Please select a campaign type.'
      })
    }
    let isCreateComplete = true
    if (!campaignCreateWorkflow.creativeInfo.isCustom) {
      if (campaignType === BuilderCampaignType.Native) {
        const nativeDetails = campaignCreateWorkflow.creativeInfo.nativeDetails
        if (!nativeDetails || nativeDetails.length <= 0) {
          isCreateComplete = false
        } else {
          const isValidNative = (details: NativeDetails) => {
            return (details.headline?.length ?? 0 > 0) && (details.body?.length ?? 0 > 0) && (details.companyName?.length ?? 0 > 0) && (details.buttonText?.length ?? 0 > 0) && (details.clickUrl?.length ?? 0 > 0) && details.acImage
          }
          isCreateComplete = nativeDetails.every(isValidNative)
        }
      }
      if (campaignType === BuilderCampaignType.Display) {
        const displayDetails = campaignCreateWorkflow.creativeInfo.displayDetails
        if (!displayDetails || displayDetails.length <= 0) {
          isCreateComplete = false
        } else {
          const isValidDisplay = (details: DisplayDetails) => {
            return (details.displayImageUrl ?? '') !== '' && details.displayWidth !== 0 && details.displayHeight !== 0 && (details.clickUrl ?? '').length > 0
          }
          isCreateComplete = displayDetails.every(isValidDisplay)
        }
      }
      if (campaignType === BuilderCampaignType.TV) {
        const videoDetails = campaignCreateWorkflow.creativeInfo.videoDetails
        if (!videoDetails || videoDetails.length <= 0) {
          isCreateComplete = false
        } else {
          const isValidTv = (details: VideoDetails) => {
            return (details.uploadedUrl ?? '') !== '' || (details.previewUrl ?? '') !== ''
          }
          isCreateComplete = videoDetails.every(isValidTv)
        }
      }
      if (campaignType === BuilderCampaignType.Billboard) {
        const billboardDetails = campaignCreateWorkflow.creativeInfo.billboardDetails
        if (!billboardDetails || billboardDetails.length <= 0) {
          isCreateComplete = false
        } else {
          const isValidBillboard = (details: BillboardDetails) => {
            return (details.uploadedUrl ?? '') !== ''
          }
          isCreateComplete = billboardDetails.every(isValidBillboard)
        }
      }
    }
    let isTargetComplete: boolean
    if (campaignCreateWorkflow.campaignTargeting.targetingType === TargetingType.Audience) {
      const audienceTargeting = campaignCreateWorkflow.campaignTargeting.audienceTargeting
      isTargetComplete = (audienceTargeting.uploadedAudienceUrl ?? '').length > 0 || audienceTargeting.segments.length > 0
    } else if (campaignCreateWorkflow.campaignTargeting.targetingType === TargetingType.CustomAudience) {
      const audienceTargeting = campaignCreateWorkflow.campaignTargeting.audienceTargeting
      isTargetComplete = (audienceTargeting.uploadedAudienceUrl ?? '').length > 0
    } else if (campaignCreateWorkflow.campaignTargeting.targetingType === TargetingType.Location) {
      const locationTargeting = campaignCreateWorkflow.campaignTargeting.locationTargeting
      const isLocationTargetingValid = (details: LocationTargeting) => {
        if (details.targetingType === LocationTargetingType.Address && details.addresses?.length === 0) {
          return false
        }
        if (details.targetingType === LocationTargetingType.City && details.cities.length === 0) {
          return false
        }
        if (details.targetingType === LocationTargetingType.DMA && details.dmas.length === 0) {
          return false
        }
        if (details.targetingType === LocationTargetingType.Region && details.regions.length === 0) {
          return false
        }
        if (details.targetingType === LocationTargetingType.Zip && details.zips.length === 0) {
          return false
        }
        if (details.targetingType == LocationTargetingType.Map) {
          return true
        }

        return true
      }
      isTargetComplete = isLocationTargetingValid(locationTargeting)
    } else if (campaignCreateWorkflow.campaignTargeting.targetingType === TargetingType.Retarget) {
      const retargeting = campaignCreateWorkflow.campaignTargeting.audienceTargeting.retargeting
      if (!retargeting) {
        isTargetComplete = false
      } else {
        isTargetComplete = retargeting.retargetCampaignIds.length > 0 || retargeting.entireSite || retargeting.retargetWebsiteUrls.length > 0
      }
    } else if (campaignCreateWorkflow.campaignTargeting.targetingType === TargetingType.Smart) {
      const smartTargeting = campaignCreateWorkflow.campaignTargeting.smartTargeting
      if (smartTargeting.targetArea === SmartTargeterAreaType.Country) {
        isTargetComplete = true
      } else if (smartTargeting.targetArea === SmartTargeterAreaType.State) {
        isTargetComplete = (smartTargeting.state ?? '').length > 0
      } else {
        isTargetComplete = (smartTargeting.streetAddress ?? '').length > 0 && (smartTargeting.city ?? '').length > 0 && (smartTargeting.state ?? '').length > 0 && (smartTargeting.zip ?? '').length > 0
      }
    } else {
      isTargetComplete = false
    }

    let isDeliveryComplete = true
    setBuilderComplete({ isComplete: isCreateComplete && isTargetComplete && isDeliveryComplete, isCreateComplete, isTargetComplete, isDeliveryComplete })
  }, [campaignCreateWorkflow])

  return {
    beginTvCampaign,
    beginInternetCampaign,
    beginBillboardCampaign,
    navigateCreate,
    builderNativeSuggestedSelected,
    builderNativeUploadSelected,
    builderDisplayUploadSelected,
    builderDisplayCustomSelected,
    builderTvSuggestedSelected,
    builderTvUploadSelected,
    builderTvCustomSelected,
    builderBillboardUploadSelected,
    builderBillboardCustomSelected,
    customAdContinue,
    suggestedNativeSelected,
    suggestedTvSelected,
    createTvComplete,
    createNativeComplete,
    createDisplayComplete,
    createBillboardComplete,
    navigateTargeting,
    deliveryComplete,
    targetAudienceSelected,
    targetCustomAudienceSelected,
    targetLocationSelected,
    targetRetargetingSelected,
    targetImportTemplateSelected,
    targetSmartSelected,
    targetAudienceComplete,
    targetImportTemplateComplete,
    targetLocationComplete,
    targetRetargetingComplete,
    navigateDelivery,
    skipAdBuilder,
    skipTargeting,
    builderComplete
  }
}

export interface builderComplete {
  isComplete: boolean
  isCreateComplete?: boolean
  isTargetComplete?: boolean
  isDeliveryComplete?: boolean
  note?: string
}
