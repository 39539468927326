import { TargetingType } from './CampaignTargeting'

export type TargetingTemplate = {
  id: string
  organizationId: string
  name: string
  accountId?: string
  locationTargeting: LocationTargeting
  audienceTargeting: AudienceTargeting
  tvTargeting: TvTargeting
  billboardTargeting: BaseTargeting
  internetTargeting: InternetTargeting
  targetingPriority: TargetingType
}

export type AgeRange = 'all' | '18_24' | '25_34' | '35_44' | '45_54' | '55_64' | '65'
export type Gender = 'all' | 'female' | 'male'
export type HouseholdIncome = 'all' | 'less50' | '50to100' | '100to150' | '150to200' | 'more200'

export type AudienceTargeting = {
  ageRanges: AgeRange[]
  genders: Gender[]
  householdIncomes: HouseholdIncome[]
  uploadedAudienceUrl?: string
}

export enum LocationTargetingType {
  USA = 'Country',
  Region = 'Region',
  DMA = 'DMA',
  City = 'City',
  Zip = 'Zip',
  Map = 'Map',
  Address = 'Address',
  Uploaded = 'Uploaded'
}

export type LocationTargeting = {
  locationType: LocationTargetingType
  regions: Region[]
  dmas: Dma[]
  cities: City[]
  postalCodes: PostalCode[]
  uploadedAudienceUrl?: string
}

export type InternetLocationTargeting = LocationTargeting & {
  geoFences: GeoFence[]
  geoCircles: GeoCircle[]
  geoPolygons: GeoPolygon[]
  geoRectangles: GeoRectangle[]
  addresses: Address[]
}

export type Dma = {
  id: number
  name: string
}

export type Region = {
  id: number
  code: string
  name: string
}

export type City = {
  id: number
  name: string
  regionCode: string
}

export type PostalCode = {
  id: number
  code: string
  name: string
}

export type GeoFence = {
  placeId: string
  formattedAddress: string
  city?: string
  state?: string
  zip?: string
  lat: number
  lng: number
  radius: number
}

type GeoPoint = {
  lat: number
  lng: number
}

export type GeoCircle = {
  id: string
  center: GeoPoint
  radius: number
}

export type GeoPolygon = GeoCircle & {
  points: GeoPoint[]
}

export type GeoRectangle = GeoCircle & {
  northEast: GeoPoint
  southWest: GeoPoint
}

export type Address = {
  streetAddress: string
  city: string
  state: string
  zip: string
  country: string
}

export type DayPartitioning = {
  sunday: number[]
  monday: number[]
  tuesday: number[]
  wednesday: number[]
  thursday: number[]
  friday: number[]
  saturday: number[]
}

export enum FrequencyCapUnit {
  Hourly = 'Hourly',
  Daily = 'Daily',
  Weekly = 'Weekly'
}

export type FrequencyCapSettings = {
  frequency?: number
  unit?: FrequencyCapUnit
}

export type BaseTargeting = {
  cpm?: number
  dayParts?: DayPartitioning
  frequencyCapSettings?: FrequencyCapSettings
  audienceTargeting?: AudienceTargeting
  locationTargeting?: LocationTargeting
}

export type InternetTargeting = BaseTargeting & {
  segments?: Segment[]
  advancedInternetTargeting?: {
    advancedTargetingType?: string
    audienceType?: string
    retargetingType?: string
    inventorySettings?: InventorySettings
    deviceSettings?: DeviceSettings
  }
  locationTargeting?: InternetLocationTargeting
}

export type InventorySettings = {
  targetWeb?: boolean
  targetApp?: boolean
}

export type DeviceSettings = {
  targetPc?: boolean
  targetPhone?: boolean
  targetTablet?: boolean
}

export type Segment = {
  id: number
  name: string
  description?: string
  hierarchy: string[]
}

export type TvTargeting = BaseTargeting & {
  segments?: Segment[]
}

export interface CreateTargetingRequest {
  name: string
  accountId?: string
  locationTargeting: LocationTargeting
  audienceTargeting: AudienceTargeting
  tvTargeting: TvTargeting
  billboardTargeting: BaseTargeting
  internetTargeting: InternetTargeting
  targetingPriority: TargetingType
}
