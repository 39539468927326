import { useEffect, useState } from 'react'

import { EuiButtonIcon, EuiCopy, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiRadio, EuiSpacer, EuiText } from '@elastic/eui'

import { City, Dma, LocationTargeting, LocationTargetingType, PostalCode } from 'api/interfaces/TargetingTemplate'

import { TargetingType } from '../../../api/interfaces/CampaignTargeting'
import { AcTargeterSelection } from '../finders/AcTargeterSelection'
import { CityFinderXandr } from '../finders/CityFinderXandr'
import { DMAFinderXandr } from '../finders/DMAFinderXandr'
import { PostalCodeFinderXandr } from '../finders/PostalCodeFinderXandr'
import { RegionFinderXandr } from '../finders/RegionFinderXandr'

export interface ILocationTargetingForm {
  targetingType: TargetingType
  locationTargeting: LocationTargeting
  onLocationTargetingChange: (locationTargeting: LocationTargeting) => void
}

export const LocationTargetingForm: React.FC<ILocationTargetingForm> = ({ targetingType, locationTargeting, onLocationTargetingChange }) => {
  const [zipsAsCsv, setZipsAsCsv] = useState('')

  useEffect(() => {
    if (locationTargeting.postalCodes) {
      setZipsAsCsv(locationTargeting.postalCodes?.map(r => r.code).join(','))
    }
  }, [locationTargeting.postalCodes])

  return (
    <>
      <EuiRadio
        id='state'
        name='locationType'
        value='State'
        label={
          <EuiText size='s'>
            <strong>Targeting Using State</strong> (one or more U.S. States)
          </EuiText>
        }
        checked={locationTargeting.locationType === LocationTargetingType.Region}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.Region })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='dma'
        name='locationType'
        value='DMA'
        label={
          <EuiText size='s'>
            <strong>Targeting Using DMA/Metro Area</strong> (one or more DMAs)
          </EuiText>
        }
        checked={locationTargeting.locationType === LocationTargetingType.DMA}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.DMA })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='city'
        name='locationType'
        value='City'
        label={
          <EuiText size='s'>
            <strong>Targeting Using City</strong> (one or more City names)
          </EuiText>
        }
        checked={locationTargeting.locationType === LocationTargetingType.City}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.City })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='zip'
        name='locationType'
        value='Zip'
        label={
          <EuiText size='s'>
            <strong>Targeting Using Zip Code</strong> (one or more Zip Codes)
          </EuiText>
        }
        checked={locationTargeting.locationType === LocationTargetingType.Zip}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.Zip })
        }}
      />
      <EuiSpacer size='s' />
      {locationTargeting.locationType === LocationTargetingType.Region && (
        <>
          <EuiFormRow fullWidth label={'Region'}>
            <RegionFinderXandr
              onOptionClick={region => {
                onLocationTargetingChange({ ...locationTargeting, regions: [...(locationTargeting.regions ?? []), region] })
              }}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.regions?.map(r => ({ key: r.id.toString(), label: r.name ?? '' })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, regions: locationTargeting.regions?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === LocationTargetingType.DMA && (
        <>
          <EuiFormRow fullWidth label={'DMAs'}>
            <DMAFinderXandr onOptionClick={(dma: Dma) => onLocationTargetingChange({ ...locationTargeting, dmas: [...(locationTargeting.dmas ?? []), dma] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.dmas?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, dmas: locationTargeting.dmas?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === LocationTargetingType.City && (
        <>
          <EuiFormRow fullWidth label={'Cities'}>
            <CityFinderXandr onOptionClick={(city: City) => onLocationTargetingChange({ ...locationTargeting, cities: [...(locationTargeting.cities ?? []), city] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.cities?.map(r => ({ key: r.id.toString(), label: `${r.name}, ${r.regionCode}` })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, cities: locationTargeting.cities?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === LocationTargetingType.Zip && (
        <>
          <EuiFormRow fullWidth label={'Zips'}>
            <PostalCodeFinderXandr onOptionClick={(zip: PostalCode) => onLocationTargetingChange({ ...locationTargeting, postalCodes: [...(locationTargeting.postalCodes ?? []), zip] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.postalCodes?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, postalCodes: locationTargeting.postalCodes?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiCopy textToCopy={zipsAsCsv}>{copy => <EuiButtonIcon title={'Copy as CSV'} iconType={'copy'} onClick={copy} />}</EuiCopy>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
        </>
      )}
    </>
  )
}
