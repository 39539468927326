import { FrequencyUnit } from '../api/interfaces'
import { AdPlacementType, CampaignDelivery } from '../api/interfaces/CampaignDelivery'
import { AudienceTargeting, LocationTargeting } from '../api/interfaces/CampaignTargeting'
import { Address, AudienceTargeting as AudienceTemplate, AudienceTargeting as AudienceTargetingTemplate, City, DayPartitioning, DeviceSettings, Dma, FrequencyCapSettings, FrequencyCapUnit, GeoCircle, GeoFence, GeoPolygon, GeoRectangle, InventorySettings, LocationTargeting as LocationTargetingTemplate, LocationTargetingType, PostalCode, Region, Segment } from '../api/interfaces/TargetingTemplate'
import { DefaultTargetingState } from '../features/builders/DefaultTargetingSlice'
import { BuilderCampaignType } from '../workflows/CampaignCreateWorkflow'
import { DEFAULT_AUDIENCE_TARGETING, DEFAULT_DAY_PARTS, DEFAULT_DEVICE_SETTINGS, DEFAULT_INVENTORY_SETTINGS, DEFAULT_LOCATION_TARGETING } from './TargetingFormDefaults'

const convertFrequencyUnit = (unit?: FrequencyCapUnit): FrequencyUnit => {
  switch (unit) {
    case FrequencyCapUnit.Hourly:
      return FrequencyUnit.hour
    case FrequencyCapUnit.Daily:
      return FrequencyUnit.day
    case FrequencyCapUnit.Weekly:
      return FrequencyUnit.week
    default:
      return FrequencyUnit.week
  }
}

export const getCampaignDeliveryFromTemplate = ({ dayParts, frequencyCapSettings, inventorySettings, deviceSettings }: { dayParts: DayPartitioning; frequencyCapSettings: FrequencyCapSettings; inventorySettings: InventorySettings; deviceSettings: DeviceSettings }): CampaignDelivery => {
  return {
    dayParts,
    frequency: frequencyCapSettings.frequency ?? 12,
    frequencyUnit: convertFrequencyUnit(frequencyCapSettings.unit),
    inventorySettings: {
      targetApp: inventorySettings.targetApp ?? false,
      targetWeb: inventorySettings.targetWeb ?? true
    },
    deviceSettings: {
      targetPc: deviceSettings.targetPc ?? true,
      targetPhone: deviceSettings.targetPhone ?? true,
      targetTablet: deviceSettings.targetTablet ?? true
    },
    placement: AdPlacementType.Everywhere,
    categories: [],
    specific: []
  }
}

export const getAudienceTargetingFromTemplate = (audienceTemplate: AudienceTargetingTemplate, segments?: Segment[]): AudienceTargeting => {
  return {
    demographics: {
      ageRanges: audienceTemplate.ageRanges,
      genders: audienceTemplate.genders,
      householdIncomes: audienceTemplate.householdIncomes
    },
    segments: segments ?? []
  }
}

export const getLocationTargetingFromTemplate = ({ locationType, regions, dmas, cities, postalCodes, uploadedAudienceUrl, geoCircles, geoPolygons, geoRectangles, addresses }: { locationType: LocationTargetingType; regions: Region[]; dmas: Dma[]; cities: City[]; postalCodes: PostalCode[]; uploadedAudienceUrl?: string; geoFences?: GeoFence[]; geoCircles?: GeoCircle[]; geoPolygons?: GeoPolygon[]; geoRectangles?: GeoRectangle[]; addresses?: Address[] }): LocationTargeting => {
  return {
    targetingType: locationType,
    regions,
    dmas,
    cities,
    zips: postalCodes,
    addresses: addresses ?? [],
    geoCircles: geoCircles ?? [],
    geoRectangles: geoRectangles ?? [],
    geoPolygons: geoPolygons ?? [],
    uploadedAudienceUrl
  }
}

export const getAudienceTemplateByCampaignType = (defaultTargetingTemplate: DefaultTargetingState, campaignType: BuilderCampaignType): AudienceTemplate => {
  switch (campaignType) {
    case BuilderCampaignType.TV:
      return defaultTargetingTemplate.tvTargeting.audienceTargeting ?? defaultTargetingTemplate.audienceTargeting
    case BuilderCampaignType.Native:
    case BuilderCampaignType.Display:
      return defaultTargetingTemplate.internetTargeting.audienceTargeting ?? defaultTargetingTemplate.audienceTargeting
    case BuilderCampaignType.Billboard:
      return defaultTargetingTemplate.billboardTargeting.audienceTargeting ?? defaultTargetingTemplate.audienceTargeting
    default:
      return DEFAULT_AUDIENCE_TARGETING
  }
}

export const getLocationTemplateByCampaignType = (defaultTargetingTemplate: DefaultTargetingState, campaignType: BuilderCampaignType): { locationType: LocationTargetingType; regions: Region[]; dmas: Dma[]; cities: City[]; postalCodes: PostalCode[]; uploadedAudienceUrl?: string; geoCircles?: GeoCircle[]; geoPolygons?: GeoPolygon[]; geoRectangles?: GeoRectangle[]; addresses?: Address[] } => {
  switch (campaignType) {
    case BuilderCampaignType.TV:
      return defaultTargetingTemplate.tvTargeting.locationTargeting ?? defaultTargetingTemplate.locationTargeting
    case BuilderCampaignType.Native:
    case BuilderCampaignType.Display:
      return defaultTargetingTemplate.internetTargeting.locationTargeting ?? defaultTargetingTemplate.locationTargeting
    case BuilderCampaignType.Billboard:
      return defaultTargetingTemplate.billboardTargeting.locationTargeting ?? defaultTargetingTemplate.locationTargeting
    default:
      return DEFAULT_LOCATION_TARGETING
  }
}

export const getCampaignDeliveryTemplateByCampaignType = (defaultTargetingTemplate: DefaultTargetingState, campaignType: BuilderCampaignType): { dayParts: DayPartitioning; frequencyCapSettings: FrequencyCapSettings; inventorySettings: InventorySettings; deviceSettings: DeviceSettings } => {
  switch (campaignType) {
    case BuilderCampaignType.TV:
      return {
        dayParts: defaultTargetingTemplate.tvTargeting.dayParts ?? DEFAULT_DAY_PARTS,
        frequencyCapSettings: {
          frequency: defaultTargetingTemplate.tvTargeting.frequencyCapSettings?.frequency ?? 12,
          unit: defaultTargetingTemplate.tvTargeting.frequencyCapSettings?.unit ?? FrequencyCapUnit.Weekly
        },
        inventorySettings: DEFAULT_INVENTORY_SETTINGS,
        deviceSettings: DEFAULT_DEVICE_SETTINGS
      }
    case BuilderCampaignType.Native:
    case BuilderCampaignType.Display:
      return {
        dayParts: defaultTargetingTemplate.internetTargeting.dayParts ?? DEFAULT_DAY_PARTS,
        frequencyCapSettings: {
          frequency: defaultTargetingTemplate.internetTargeting.frequencyCapSettings?.frequency ?? 12,
          unit: defaultTargetingTemplate.internetTargeting.frequencyCapSettings?.unit ?? FrequencyCapUnit.Weekly
        },
        inventorySettings: defaultTargetingTemplate.internetTargeting.advancedInternetTargeting?.inventorySettings ?? DEFAULT_INVENTORY_SETTINGS,
        deviceSettings: defaultTargetingTemplate.internetTargeting.advancedInternetTargeting?.deviceSettings ?? DEFAULT_DEVICE_SETTINGS
      }
    case BuilderCampaignType.Billboard:
      return {
        dayParts: defaultTargetingTemplate.billboardTargeting.dayParts ?? DEFAULT_DAY_PARTS,
        frequencyCapSettings: {
          frequency: defaultTargetingTemplate.billboardTargeting.frequencyCapSettings?.frequency ?? 12,
          unit: defaultTargetingTemplate.billboardTargeting.frequencyCapSettings?.unit ?? FrequencyCapUnit.Weekly
        },
        inventorySettings: DEFAULT_INVENTORY_SETTINGS,
        deviceSettings: DEFAULT_DEVICE_SETTINGS
      }
    default:
      return {
        dayParts: DEFAULT_DAY_PARTS,
        frequencyCapSettings: {
          frequency: 12,
          unit: FrequencyCapUnit.Weekly
        },
        inventorySettings: DEFAULT_INVENTORY_SETTINGS,
        deviceSettings: DEFAULT_DEVICE_SETTINGS
      }
  }
}

export const getSegmentsFromTemplate = (defaultTargetingTemplate: DefaultTargetingState, campaignType: BuilderCampaignType): Segment[] => {
  switch (campaignType) {
    case BuilderCampaignType.TV:
      return defaultTargetingTemplate.tvTargeting.segments ?? []
    case BuilderCampaignType.Native:
    case BuilderCampaignType.Display:
      return defaultTargetingTemplate.internetTargeting.segments ?? []
    default:
      return []
  }
}
