import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { EuiFlexGroup, EuiFlexItem, EuiForm, EuiRadioGroup, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { LocationTargetingType, TargetingType } from '../../../../api/interfaces/CampaignTargeting'
import { AgeRange, AudienceTargeting, FrequencyCapUnit, Gender, HouseholdIncome, InternetLocationTargeting, InternetTargeting } from '../../../../api/interfaces/TargetingTemplate'
import { RootState } from '../../../../app/rootReducer'
import { BackNextFooter } from '../../../../components/navigation/BackNextFooter'
import { DefaultDayParts } from '../../../../components/targeters/DayPartPicker'
import { AudienceTargetingForm } from '../../../../components/targeting/TargetingForm/AudienceTargetingForm'
import { InternetLocationTargetingForm } from '../../../../components/targeting/TargetingForm/InternetLocationTargetingForm'
import { InternetTargetingForm } from '../../../../components/targeting/TargetingForm/InternetTargetingForm'
import { DEFAULT_DAY_PARTS, DEFAULT_DEVICE_SETTINGS, DEFAULT_INVENTORY_SETTINGS } from '../../../../utils/TargetingFormDefaults'
import { getAudienceTargetingFromTemplate, getCampaignDeliveryFromTemplate, getLocationTargetingFromTemplate } from '../../../../utils/TransformTargeting'
import { ICampaignBuilderWorkflowStep } from '../creativeBuilder/CreativeBuilderWorkflowManager'
import { useCampaignBuilderActions } from '../useCampaignBuilderActions'

interface ImportTargetingPageProps extends ICampaignBuilderWorkflowStep {}

export const ImportTargetingPage = (props: ImportTargetingPageProps) => {
  const campaignWorkflow = useSelector((state: RootState) => state.campaignCreateWorkflow)
  const campaignTargeting = campaignWorkflow.campaignTargeting

  const [locationTemplate, setLocationTemplate] = useState<InternetLocationTargeting>({
    locationType: campaignTargeting.locationTargeting.targetingType,
    regions: campaignTargeting.locationTargeting.regions,
    dmas: campaignTargeting.locationTargeting.dmas,
    cities: campaignTargeting.locationTargeting.cities,
    postalCodes: campaignTargeting.locationTargeting.zips,
    addresses: campaignTargeting.locationTargeting.addresses ?? [],
    geoFences: campaignTargeting.locationTargeting.geoFences ?? [],
    geoCircles: campaignTargeting.locationTargeting.geoCircles ?? [],
    geoPolygons: campaignTargeting.locationTargeting.geoPolygons ?? [],
    geoRectangles: campaignTargeting.locationTargeting.geoRectangles ?? []
  })

  const [audienceTemplate, setAudienceTemplate] = useState<AudienceTargeting>({
    ageRanges: (campaignTargeting.audienceTargeting.demographics.ageRanges as AgeRange[]) ?? ['all' as AgeRange],
    genders: (campaignTargeting.audienceTargeting.demographics.genders as Gender[]) ?? ['all' as Gender],
    householdIncomes: (campaignTargeting.audienceTargeting.demographics.householdIncomes as HouseholdIncome[]) ?? ['all' as HouseholdIncome]
  })

  const [internetTemplate, setInternetTemplate] = useState<InternetTargeting>({
    segments: campaignTargeting.audienceTargeting.segments ?? [],
    dayParts: campaignTargeting.campaignDelivery.dayParts ?? DefaultDayParts,
    frequencyCapSettings: {
      frequency: campaignTargeting.campaignDelivery.frequency,
      unit: campaignTargeting.campaignDelivery.frequencyUnit === 0 ? FrequencyCapUnit.Hourly : campaignTargeting.campaignDelivery.frequencyUnit === 1 ? FrequencyCapUnit.Daily : FrequencyCapUnit.Weekly
    },
    advancedInternetTargeting: {
      deviceSettings: {
        targetPc: campaignTargeting.campaignDelivery.deviceSettings.targetPc,
        targetPhone: campaignTargeting.campaignDelivery.deviceSettings.targetPhone,
        targetTablet: campaignTargeting.campaignDelivery.deviceSettings.targetTablet
      },
      inventorySettings: {
        targetApp: campaignTargeting.campaignDelivery.inventorySettings.targetApp,
        targetWeb: campaignTargeting.campaignDelivery.inventorySettings.targetWeb
      }
    }
  })

  const [targetingPriority, setTargetingPriority] = useState<TargetingType>(campaignTargeting.targetingType)

  const { targetImportTemplateComplete } = useCampaignBuilderActions()

  const onLocationTargetingChange = (locationTargeting: InternetLocationTargeting) => {
    setLocationTemplate(locationTargeting)
  }

  const onAudienceTargetingChanged = (audienceTargeting: AudienceTargeting) => {
    setAudienceTemplate(audienceTargeting)
  }

  const onInternetTargetingChanged = (internetTargeting: InternetTargeting) => {
    setInternetTemplate(internetTargeting)
  }

  const onTargetingPrioritizationChange = (targetingType: TargetingType) => {
    setTargetingPriority(targetingType)
  }

  const onContinueClick = () => {
    const audienceTargeting = getAudienceTargetingFromTemplate(audienceTemplate, internetTemplate.segments)

    const locationTargeting = getLocationTargetingFromTemplate(locationTemplate)

    const campaignDelivery = getCampaignDeliveryFromTemplate({
      dayParts: internetTemplate.dayParts ?? DEFAULT_DAY_PARTS,
      frequencyCapSettings: {
        frequency: internetTemplate.frequencyCapSettings?.frequency ?? 12,
        unit: internetTemplate.frequencyCapSettings?.unit ?? FrequencyCapUnit.Weekly
      },
      inventorySettings: internetTemplate.advancedInternetTargeting?.inventorySettings ?? DEFAULT_INVENTORY_SETTINGS,
      deviceSettings: internetTemplate.advancedInternetTargeting?.deviceSettings ?? DEFAULT_DEVICE_SETTINGS
    })

    targetImportTemplateComplete(locationTargeting, audienceTargeting, campaignDelivery, targetingPriority)
  }

  const radios = [
    {
      id: TargetingType.Location,
      label: 'Location'
    },
    {
      id: TargetingType.Audience,
      label: 'Audience'
    }
  ]

  const isValid = () => {
    if (targetingPriority === 'Audience') {
      return internetTemplate.segments && internetTemplate.segments.length > 0
    } else {
      if (locationTemplate.locationType === LocationTargetingType.Address && locationTemplate.addresses.length === 0) {
        return false
      }
      if (locationTemplate.locationType === LocationTargetingType.City && locationTemplate.cities.length === 0) {
        return false
      }
      if (locationTemplate.locationType === LocationTargetingType.DMA && locationTemplate.dmas.length === 0) {
        return false
      }
      if (locationTemplate.locationType === LocationTargetingType.Region && locationTemplate.regions.length === 0) {
        return false
      }
      if (locationTemplate.locationType === LocationTargetingType.Zip && locationTemplate.postalCodes.length === 0) {
        return false
      }
      if (locationTemplate.locationType === LocationTargetingType.Map) {
        return true
      }
      return true
    }
  }

  return (
    <>
      <Helmet>
        <title>Import Targeting Template</title>
      </Helmet>
      <EuiFlexGroup direction={'column'}>
        <EuiFlexItem>
          <EuiForm>
            <EuiTitle size={'s'}>
              <h2>Targeting Prioritization</h2>
            </EuiTitle>
            <EuiRadioGroup options={radios} idSelected={targetingPriority} onChange={id => onTargetingPrioritizationChange(id as TargetingType)} name='targeting prioritization radio group' />
            <EuiSpacer />
            <EuiTitle size={'s'}>
              <h2>Location Targeting</h2>
            </EuiTitle>
            <InternetLocationTargetingForm locationTargeting={locationTemplate} onLocationTargetingChange={onLocationTargetingChange} targetingType={targetingPriority} />
            <EuiSpacer />
            <EuiTitle size={'s'}>
              <h2>Demographic Targeting</h2>
            </EuiTitle>
            <AudienceTargetingForm audienceTargeting={audienceTemplate} onAudienceTargetingChanged={onAudienceTargetingChanged} />
            <EuiSpacer />
            {(campaignWorkflow.campaignType === 'Native' || campaignWorkflow.campaignType === 'Display') && <InternetTargetingForm internetTargeting={internetTemplate} onInternetTargetingChanged={onInternetTargetingChanged} />}
          </EuiForm>
        </EuiFlexItem>
        {!isValid() && (
          <EuiFlexItem>
            <EuiText color='warning' size={'xs'}>
              {targetingPriority === 'Audience' && 'Please define at least one audience.'}
              {targetingPriority === 'Location' && 'Please select at least one location.'}
            </EuiText>
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={true}>
          <BackNextFooter onBack={props.onBack} onNext={onContinueClick} isNextDisabled={!isValid()} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}
