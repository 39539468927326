import { TargetingType } from '../api/interfaces/CampaignTargeting'
import { AudienceTargeting, DayPartitioning, DeviceSettings, FrequencyCapUnit, InternetLocationTargeting, InventorySettings, LocationTargeting, LocationTargetingType } from '../api/interfaces/TargetingTemplate'
import { TargetingFields } from '../components/targeting/TargetingForm/TargetingForm'

export const DEFAULT_DAY_PARTS: DayPartitioning = {
  monday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
  tuesday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
  wednesday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
  thursday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
  friday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
  saturday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
  sunday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]
}

export const DEFAULT_LOCATION_TARGETING: LocationTargeting = {
  locationType: LocationTargetingType.Region,
  regions: [],
  postalCodes: [],
  cities: [],
  dmas: []
}

export const DEFAULT_INTERNET_LOCATION_TARGETING: InternetLocationTargeting = {
  locationType: LocationTargetingType.Map,
  regions: [],
  postalCodes: [],
  cities: [],
  dmas: [],
  addresses: [],
  geoFences: [],
  geoPolygons: [],
  geoRectangles: [],
  geoCircles: []
}

export const DEFAULT_AUDIENCE_TARGETING: AudienceTargeting = {
  ageRanges: ['all'],
  genders: ['all'],
  householdIncomes: ['all']
}

export const DEFAULT_INVENTORY_SETTINGS: InventorySettings = {
  targetApp: false,
  targetWeb: true
}

export const DEFAULT_DEVICE_SETTINGS: DeviceSettings = {
  targetPc: true,
  targetPhone: true,
  targetTablet: true
}

export const DEFAULT_TARGETING_FIELDS: TargetingFields = {
  name: 'New Targeting',
  targetingPriority: TargetingType.Location,
  locationTargeting: DEFAULT_LOCATION_TARGETING,
  audienceTargeting: DEFAULT_AUDIENCE_TARGETING,
  internetTargeting: {
    segments: [],
    dayParts: DEFAULT_DAY_PARTS,
    frequencyCapSettings: {
      frequency: 0,
      unit: FrequencyCapUnit.Hourly
    },
    advancedInternetTargeting: {
      inventorySettings: DEFAULT_INVENTORY_SETTINGS,
      deviceSettings: DEFAULT_DEVICE_SETTINGS
    }
  },
  tvTargeting: {
    segments: [],
    frequencyCapSettings: {
      frequency: 12,
      unit: FrequencyCapUnit.Weekly
    },
    dayParts: DEFAULT_DAY_PARTS
  },
  billboardTargeting: {
    dayParts: DEFAULT_DAY_PARTS
  }
}
